.navcnt {
  height: 80px; /* Fixed height instead of vh */
  width: 100%;
  padding: 0 3%;
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  transition: all 0.3s ease;
}
.navleft {
  display: flex;
  align-items: center;
}

.navleft img {
  height: 40px;
  transition: transform 0.3s ease;
  margin-right: 15px; /* Add space between logo and company name */
}
.company-name {
  font-size: 18px;
  font-weight: 500;
  color: #333;
  white-space: nowrap; /* Prevent line breaks */
}

.navleft img:hover {
  transform: scale(1.05);
}

.navright {
  display: flex;
  align-items: center;
}

.menues {
  display: flex;
  align-items: center;
}

.menu1 {
  padding: 10px 15px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  color: #333;
  transition: all 0.3s ease;
  position: relative;
}

.menu1:hover {
  color: rgb(0, 48, 131);
}

.menu1::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 50%;
  background-color: rgb(0, 48, 131);
  transition: all 0.3s ease;
}

.menu1:hover::after {
  width: 100%;
  left: 0;
}

.hamburger {
  cursor: pointer;
  display: none;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
}

.modal.show {
  opacity: 1;
  visibility: visible;
}

.modal-close {
  position: absolute;
  top: 20px;
  right: 30px;
  font-weight: 900;
  cursor: pointer;
  color: #fff;
  font-size: 24px;
  transition: all 0.3s ease;
}

.modal-close:hover {
  color: rgb(0, 48, 131);
  transform: rotate(90deg);
}

.modal1-content {
  background-color: #fff;
  padding: 40px;
  border-radius: 10px;
  text-align: center;
  transform: scale(0.8);
  transition: all 0.3s ease;
}

.modal.show .modal1-content {
  transform: scale(1);
}

.modal1-content .menu1-modal {
  padding: 15px 0;
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
  color: #333;
  transition: all 0.3s ease;
}

.modal1-content .menu1-modal:hover {
  color: rgb(0, 48, 131);
}

@media screen and (max-width: 768px) {
  .menu1 {
    display: none;
  }

  .hamburger {
    display: block;
  }

  .navcnt {
    height: 60px;
    padding: 0 2%; /* Further reduce padding on mobile */
  }

  .navleft img {
    height: 30px; /* Slightly reduce logo size on mobile */
  }

  .company-name {
    font-size: 14px; /* Reduce font size on mobile */
    max-width: 150px; /* Limit width to prevent overflow */
  }
}/* Add a media query for very small screens */
@media screen and (max-width: 480px) {
  .company-name {
    max-width: 120px; /* Further limit width on very small screens */
  }
}