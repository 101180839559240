.footer {
  background-color: #003083;
  padding: 25px 0 20px;
  color: #eae7e7;

}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.footer-logo {
  flex-basis: 25%;
}

.footer-logo img {
  max-width: 100px;
}

.footer-logo p {
  margin-bottom: 15px;
}

.social-icons {
  display: flex;
  gap: 10px;
}

.social-icons svg {
  font-size: 30px;
  color: #c3c5c7;
}

.footer-links, .footer-services, .footer-contact {
  flex-basis: 20%;
}

.footer h3 {
  margin-bottom: 20px;
  font-size: 22px;
  color: #a8a4a4;
}

.footer ul {
  list-style-type: none;
  padding: 0;
}

.footer ul li {
  margin-bottom: 10px;
}

.footer ul li a {
  color: #6c757d;
  text-decoration: none;
}

.footer-contact p {
  margin-bottom: 10px;
}

.footer-contact img {
  max-width: 100%;
  margin-top: 10px;
}

.footer-bottom {
  margin-top: 30px;
  text-align: center;
  border-top: 1px solid #dee2e6;
  padding-top: 20px;
}
.footer ul li, .footer-logo img {
  cursor: pointer;
}

.footer ul li:hover {
  color: #007bff;
}
@media (max-width: 768px) {
  .footer-content > div {
    flex-basis: 100%;
    margin-bottom: 30px;
  }
}