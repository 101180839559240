@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap');

.company-overview {
  background-color: #ffffff;
  width: 100%;
}

.parallax-image {
  background-image: url(https://images.pexels.com/photos/3184635/pexels-photo-3184635.jpeg?auto=compress&cs=tinysrgb&w=600);
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 400px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 48, 131, 0.7);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.overview-heading, .company-name {
  color: #000000;
  text-align: center;
  margin: 0;
}
.overview{
  margin: 8px;
  font-size: 3.6rem;
  font-weight: 700;
}
.overview-heading {
  font-size: 3rem;
  font-weight: 600;
  margin-bottom: 1rem;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.company{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}
.company-overview {
  margin: 8px;
  font-size: 3.6rem;
  font-weight: 300;
}
.company-name {
  font-size: 1.5rem;
  font-weight: 300;
}

.cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
  padding: 4rem 2rem;
  background-color: #ffffff;
}

.overview-card {
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 10px 20px rgba(0, 48, 131, 0.1);
  padding: 2rem;
  width: calc(33.333% - 2rem);
  min-width: 300px;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.overview-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: linear-gradient(90deg, #003083, #0055a4);
}

.overview-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(0, 48, 131, 0.2);
}

.card-icon {
  font-size: 2.5rem;
  color: #003083;
  margin-bottom: 1.5rem;
  transition: transform 0.3s ease;
}

.overview-card:hover .card-icon {
  transform: scale(1.1);
}

.card-title {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #003083;
  font-weight: 600;
}

.card-content {
  font-size: 1rem;
  color: #4a4a4a;
  line-height: 1.6;
}

@media screen and (max-width: 1024px) {
  .overview-card {
    width: calc(50% - 2rem);
  }
}

@media screen and (max-width: 768px) {
  .overview-heading {
    font-size: 2.5rem;
  }
  .overview{
    font-size: 2rem;
  }
  
  .company-name {
    font-size: 1.25rem;
  }
}

@media screen and (max-width: 480px) {
  .overview-card {
    width: 100%;
  }
  
  .overview-heading {
    font-size: 2rem;
  }
  
  .company-name {
    font-size: 1rem;
  }
  
  .cards-container {
    padding: 2rem 1rem;
  }
}