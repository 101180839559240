.headingRange {
  text-align: center;
  margin-top: 10px;
}

.container-fluid {
  width: 100%;
}

.swiper-container {
  width: 100%;
  height: 400px;
}

.swiper-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}