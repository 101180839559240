.parallax-wrapper {
  height: 150px;
  /* Adjust the height as needed */
  overflow: hidden;
}

.parallax-image {
  background-image: url(https://images.pexels.com/photos/3184635/pexels-photo-3184635.jpeg?auto=compress&cs=tinysrgb&w=600);
  /* Specify the path to your image */
  background-size: cover;
  background-position: center;
  height: 200%;
  /* Adjust the height to create the parallax effect */
  position: relative;
  z-index: -1;
}

.parallax-image::before {
  content: "";
  background-color: rgba(0, 0, 0, 0.5);
  /* Adjust the opacity and color as needed */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

#mission {
  display: flex !important;
  flex-direction: column !important;
  justify-content: start;
}

.missionPoint {
  margin-top: -15px;
  
  
}

@media screen and (max-width:768px) {
  .whyusTitle {
    font-size: 2rem;
  }

  .coreValue {
    margin-top: 100px;
  }

  .whyusTitleContent {
    width: 100% !important;
  }

  .missionPoint {
    width: 100% !important;
    padding: 0 !important;
    /* margin: 0 !important; */
    margin-top: -10px;
    ;

  }

  .missionPoint p {
    line-height: 15px;
    font-size: small;
  }
}



.notCen {
  text-align: justify !important;
}

@media screen and (max-width:425px) {
  .whyusTitle {
    font-size: 2rem;
  }

  .line {
    width: 90%;
  }

  .coreValue {
    margin-top: 50px;
  }

  .whyusTitleContent {
    font-size: small;
    width: 100% !important;
    padding: 0 !important;
    margin-top: 10px !important;
  }


}