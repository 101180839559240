/* ... (keep existing styles) */

/* New styles for Certificate section */
.certificate-section {
    background-color: #ffffff;
    padding: 20px 0;
  }
  
  .section-title {
    /* text-align: center; */
    margin: 8px;
    font-size: 3.6rem;
    font-weight: 700;
    
  }
  
  .certificate-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
  }
  
  .certificate-info {
    flex: 1;
  }
  
  .certificate-info h3 {
    color: rgb(0, 48, 131);
    font-size: 1.8rem;
    margin-bottom: 20px;
  }
  
  .certificate-info p {
    color: #555;
    font-size: 1.1rem;
    line-height: 1.6;
    margin-bottom: 30px;
  }
  
  .btn-view-certificate {
    display: inline-flex;
    align-items: center;
    background-color: rgb(0, 48, 131);
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
    font-weight: 500;
    transition: background-color 0.3s ease;
  }
  
  .btn-view-certificate:hover {
    background-color: rgb(0, 38, 104);
  }
  
  .btn-view-certificate svg {
    margin-right: 10px;
  }
  
  .certificate-image {
    flex: 0.7;
    text-align: center;
  }
  
  .certificate-image img {
    max-width: 100%;
    height: auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    border-radius: 5px;
  }
  
  @media screen and (max-width: 768px) {
    .certificate-container {
      flex-direction: column;
    }
    .section-title {
      font-size: 2rem;
      font-weight: 700;
      
    }
    .certificate-image {
      margin-top: 30px;
    }
    .certificate-info h3 {
     
      font-size: 1.4rem;
      margin-top:-20px;
      margin-bottom: 10px;
    }
    
  }