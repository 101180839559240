.containwyus {
    width: 100%;
    /* min-height: 100vh; */
    padding: 4rem 0;
    background-color: #ffffff;
}

.upper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Open Sans', sans-serif;
}

.whyusHeading span {
    font-size: 3.6rem;
    font-weight: 700;
    color: #1B1B1B;
    font-family: 'Raleway', sans-serif !important;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
}

.statistics-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 4rem;
}

.statistic-item {
    flex-basis: calc(25% - 20px);
    text-align: center;
    background-color: #fff;
    padding: 1.5rem;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.statistic-item h2 {
    font-size: 2.5rem;
    font-weight: 700;
    color: #003083;
    margin-bottom: 0.5rem;
}

.statistic-item p {
    font-size: 1rem;
    color: #666;
}

.key-steps-container {
    background-color: #fff;
    padding: 3rem;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.key-steps-container h2 {
    font-size: 2rem;
    color: #333;
    text-align: center;
    margin-bottom: 2rem;
}

.key-steps-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
}

.key-step-item {
    display: flex;
    align-items: center;
}

.key-step-icon {
    font-size: 1.5rem;
    color: #28a745;
    margin-right: 1rem;
}

.key-step-item p {
    margin: 0;
    font-size: 1rem;
    color: #333;
}


@media screen and (max-width: 992px) {
    .statistics-container {
        justify-content: center;
    }

    .statistic-item {
        flex-basis: calc(50% - 20px);
        margin-bottom: 20px;
    }

    .key-steps-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 768px) {
    .key-steps-grid {
        grid-template-columns: 1fr;
    }

    .statistic-item {
        flex-basis: 100%;
    }
}
.rightparawhy {
    text-align: end;
}

.parawhy {
    width: 60% !important;
    text-align: center;
}

.line {
    border: 1px solid black;
    width: 10%;
}

.newclasswhy {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* flex-wrap: wrap; */
}

.childOfNewclasswhy {
    width: 33.3%;
    /* background-color: aliceblue; */
}

.kesarImage {
    /* width: 200px; */

}

.hoverbounce {
    display: flex;
    justify-content: start;
    align-items: center;

}

.lastHoverBon {
    display: flex;
    justify-content: end;
    align-items: center;


}

.titlewhy {
    padding: 6px;
    font-size: 1.3rem;
}

/* Add animation keyframes */
@keyframes bounce {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-5px);
    }

    100% {
        transform: translateY(0);
    }
}

/* Apply styles */
.hoverbounce:hover .ioshield {
    color: black !important;
    animation: bounce 0.5s;

}

/* Apply styles */
.hoverbounce .ioshield {
    transition: color 0.3s;
    /* Smooth color transition */
}

.hoverbounce:hover .titlewhy {
    color: rgb(0, 48, 131);
    /* Change text color on hover */
}

.whyush3title {
    font-size: 1.5rem;
    font-weight: 400;
    margin: 5px;
}

.borderBWThem {
    display: none;
}

@media screen and (max-width:1086px) {
    .kesarImage {
        display: none;
    }

    .newclasswhy {
        display: flex;
        justify-content: space-between;
        align-items: center;
        /* flex-wrap: wrap; */
    }

    .childOfNewclasswhy {
        width: 50%;
        margin: 0 30px;
        /* background-color: aliceblue; */
    }

    .borderBWThem {
        height: 700px;
        width: 10px;
        border-radius: 10px;
        background-color: blue;
        display: block;
    }

}

@media screen and (max-width:768px) {
    .kesarImage {
        display: block;
    }

    .newclasswhy {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin: 0 !important;

    }

    .childOfNewclasswhy {
        width: 90%;
        margin: 0 auto;
        text-align: center;
        /* background-color: aliceblue; */
    }

    .borderBWThem {
        display: none;
    }

    .parawhy {
        width: 80% !important;
        font-size: small;
        text-align: center;
    }

    .middle {
        margin: 0 !important;
    }

    .iconpara {
        margin: 0 !important;
        padding: 0 0 !important;
    }

    .hoverbounce {
        display: flex;
        flex-direction: column;
    }

    .paraicon {
        font-size: small;
    }

    .rightparawhy {
        text-align: center;
    }

    .lastHoverBon {
        display: flex;
        flex-direction: column-reverse;
    }

}

@media screen and (max-width:518px) {
    .whyusHeading span {
        font-size: 2rem;
    }

    .parawhy {
        width: 100% !important;
    }

    .whyusHeading {
        padding: 4px 0 !important;
        margin: 4px 0 !important;
    }






}

@media screen and (max-width:425px) {
    .whyusHeading span {
        font-size: 1.5rem;
    }

    .parawhy {
        width: 100% !important;
        font-size: smaller;
    }

    .whyusHeading {
        padding: 4px 0 !important;
        margin: 4px 0 !important;
    }

    .line {
        width: 50% !important;
    }



}

@media screen and (max-width:400px) {
    .kesarImage {
        display: none !important;
    }

    .childOfNewclasswhy {
        width: 100%;
        margin: auto;
        /* background-color: aliceblue; */
    }

}

