.cardslider {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid grey;
  position: relative;
}



.cardupper {
  position: relative;
  overflow: hidden;
}

.cardimg img {
  width: 100%;
  height: 270px;
  object-fit: cover;
  transition: transform 0.3s ease;
}

/* Apply zoom effect on hover */
.cardslider:hover .cardimg img {
  transform: scale(1.1);
  /* Adjust the scale factor for desired zoom effect */
}

.cardicon {
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: rgb(0, 48, 131);
}

.cardline {
  width: 100%;
  /* Ensure .cardline spans the entire width */
  height: 5px;
  background-color: rgb(0, 48, 131);
  /* Keep it visible at all times */
  position: absolute;
  /* Position relative to .cardslider */
  bottom: 30%;
  /* Align to the bottom of .cardslider */
}

.cardbtm {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sheet,
.sheettype {
  margin: 10px 0;
  color: black;
  /* Default color */
}

/* Change text color on hovering */
.cardslider:hover .sheet,
.cardslider:hover .sheettype {
  color: rgb(0, 48, 131);
}

/* Media queries for responsiveness */